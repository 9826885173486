import { useEffect, useReducer, useRef } from 'react'

import { isResponseError, ResponseError } from '@marketplace-web/shared/api-client'
import { useLatestCallback } from '@marketplace-web/shared/ui-helpers'

import { transformHomepageBlockResponse } from 'data/api/transformers/response/transform-homepage-block-response'
import { logHomeMessage, observeFetchDuration } from 'pages/Home/utils/observability'
import { useClosetContext } from '@marketplace-web/domain/vas'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

import { useHomeContext } from '../HomeProvider'
import { getHomepageBlocks } from '../utils/api'
import { blocksReducer } from '../utils/blocks-reducer'
import useColumnCount from './useColumnCount'
import useTabs from './useTabs'

type Props = {
  onEmpty: (error: ResponseError) => void
}

const useFetchHomepageBlocks = ({ onEmpty }: Props) => {
  const { currentTab } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const initialTab = useRef(currentTab)
  const columnCount = useColumnCount()
  const { fetchClosetPromo } = useClosetContext()

  const closetAbTest = useAbTest('web_homepage_lazy_load_closet')
  const isClosetLoadedLazily = closetAbTest?.variant === 'on'
  useTrackAbTest(closetAbTest)

  const [state, dispatch] = useReducer(blocksReducer, {
    isLoading: true,
    error: null,
    blocks: null,
  })

  const fetchNewTab = useLatestCallback(async (tab: typeof currentTab) => {
    dispatch({ type: 'loading' })

    const start = performance.now()
    const [response, closetResponse] = await Promise.all([
      getHomepageBlocks({ homepageSessionId, nextPageToken: null, columnCount, tab }),
      tab.feed.arePromotedClosetsEnabled && !isClosetLoadedLazily
        ? fetchClosetPromo({ fresh: true })
        : null,
    ])

    const isError = isResponseError(response)
    observeFetchDuration(performance.now() - start, { isFirstPage: true, isError })

    if (isError) {
      onEmpty(response)
      logHomeMessage(`Blocks error: ${response.message}`, `tab: ${tab.name}`)
      dispatch({ type: 'error', payload: response })
    } else {
      closetResponse?.setClosets?.()
      dispatch({ type: 'success', payload: transformHomepageBlockResponse(response) })
    }
  })

  const fetchMoreBlocks = async () => {
    const nextPageToken = state.blocks?.nextPageToken
    if (!nextPageToken) return

    dispatch({ type: 'more-blocks-loading' })

    const start = performance.now()
    const response = await getHomepageBlocks({
      tab: currentTab,
      homepageSessionId,
      nextPageToken,
      columnCount,
    })

    const isError = isResponseError(response)
    observeFetchDuration(performance.now() - start, { isFirstPage: false, isError })

    if (isError) {
      dispatch({ type: 'more-blocks-failure' })
      logHomeMessage(
        `Blocks error: failed to load more blocks. ${response.message}`,
        `tab: ${currentTab.name}`,
      )

      return
    }

    dispatch({
      type: 'more-blocks-success',
      payload: transformHomepageBlockResponse(response),
    })
  }

  useEffect(() => {
    fetchNewTab(initialTab.current)
  }, [fetchNewTab])

  return {
    blocks: state.blocks,
    fetchNewTab,
    fetchMoreBlocks,
    areBlocksLoading: state.isLoading,
    error: state.error,
  }
}

export default useFetchHomepageBlocks
