import { HomepageBlocksModel } from 'types/models/homepage-blocks'
import { HomepageBlocksDto } from 'types/dtos/homepage-blocks'
import { transformHomepageBlockEntities } from 'data/transformers/homepage-blocks'

export const transformHomepageBlockResponse = (
  response: HomepageBlocksDto,
): HomepageBlocksModel => ({
  blocks: transformHomepageBlockEntities(response.blocks),
  nextPageToken: response.next_page_token,
  showLoadMoreButton: response.show_load_more_button,
})
